import React, { Suspense, useEffect } from 'react';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isStatusInitReady } from '../../global-utils/vmFunctions/myNetworking';
import { isCommunicable } from '../../global-utils/vmFunctions/myNetworking/settings';
import storageAndCookie from '../../global-utils/storage-and-cookies';

import {
  useDispatchVmEventList,
  useCheckMemberOnlyAccessUrl,
  useCheckCommunicableOnlyAccessUrl,
  useIsUserAMember,
  isUserLoggedIn,
} from '../../global-utils';
import {
  componentsPropType,
  apiDataInfoPropType,
  apiDataPropType
} from '../../global-prop-types';
import {
  getPropsMask,
  useAddonSettings,
  useApiDataHook,
  useModuleSettingsByName
} from '../../global-hooks';
import { changeCurrentPageInfo } from '../../global-state/redux/actions';
import ComponentsArrRenderer from './ComponentsArrRenderer';
import { Loading } from '..';
import vmFunctions from '../../global-utils/vmFunctions';
import usePrevious from '../../global-hooks/usePrevious';
import OfflinePageRenderer from './OfflinePageRenderer';
import { useIsProtectedUrl } from '../componentsUtils';
import {
  checkReservedUrl,
  useUserProfileData,
  useUserProfileDataConfig,
  useUserProfileDataModuleActive
} from '../../modules/user-profile-data';
import {
  useIsActive as useIsHoldingPageActive,
  useCheckRedirectToHolding,
  RedirectToHolding
} from '../../modules/holding-page';

import {
  useIsActive as useIsEventsAirSyncActive,
  useCheckRedirectToEventsAir,
  useBookedTicketsForEventsAir,
  RedirectToEventsAirAuth,
  RedirectToNoAccess
} from '../../modules/events-air-sync';

const PageRenderer = (props) => {
  // here should get the Data :)
  // TODO: Use urlParams here
  const {
    title,
    pageName,
    handlerList,
    onEnter: onEnterProps,
    onLeave: onLeaveProps,
    id,
    apiData: apiDataGlobal,
    apiDataInfo,
    skipOfflineCheck,
    fakeRender,
    className,
    ...restProps
  } = props;
  const { urlParams, urlSearchParams } = restProps;
  const apiDataPage = useApiDataHook(apiDataInfo, urlParams, urlSearchParams);
  const {
    onEnter: onEnterTracking = [],
    onLeave: onLeaveTracking = [],
    routeMatchExcludeList
  } = useModuleSettingsByName('dataInsightTrackingModule')?.pageTracking ?? {};
  const { isActive: isMyNetworkingActive } = useSelector(
    (state) => state?.appState?.settings?.modules?.myNetworkingModule
  ) ?? {};
  // To check if the API requests are ready we need to be combined information from apiDataInfo and apiDataPage.
  // It would be more intuitive to write Object.values(apiDataPage).every((el) => el.isReady);
  // however the useApiData hook returns object only with members that are ready, so initially it is {},
  // then {apiCall1: {ready: true}} and etc. That means if we check for readines in apiDataPage we get false positive.
  const isReady = (apiDataInfo ?? []).every((el) => {
    const apiStatus = apiDataPage[getPropsMask(el)];
    // if some elements in the apiDataInfo have makeApiCallIf set to false, there is no  entry in
    // apiDataPage and chacking for apiDatPage[propMask].isRead returns `false negative` (ie. it is undefined).
    // in such case we pass it as true.
    if (apiStatus === undefined) return true;
    return apiDataPage[getPropsMask(el)]?.isReady;
  });
  const pageId = usePrevious(id);
  // TODO: pageUrlParamId should be dynamic to all url Params..
  //  Due to time pressure, it's now only for id.
  const pageUrlParamId = usePrevious(urlParams?.id);
  const dispatch = useDispatch();
  const location = useLocation();
  const dispatchVmEventList = useDispatchVmEventList();
  const isProtectedUrl = useIsProtectedUrl();
  const loggedInOnlySettings = useAddonSettings('loggedInOnly');
  const membershipOnlySettings = useAddonSettings('membershipOnly');
  const communicableOnlySettings = useAddonSettings('communicableOnly');
  const isUserAMember = useIsUserAMember();
  const isMemberOnlyAccessUrl = useCheckMemberOnlyAccessUrl()(
    window.location.pathname
  );
  const isCommunicableOnlyAccessUrl = useCheckCommunicableOnlyAccessUrl()(
    window.location.pathname
  );
  const userProfileDataConfig = useUserProfileDataConfig();
  const userProfileData = useUserProfileData();
  const isUserProfileDataActive = useUserProfileDataModuleActive();
  const isHoldingPageActive = useIsHoldingPageActive();
  const checkRedirectToHolding = useCheckRedirectToHolding();
  const isEventsAirSyncActive = useIsEventsAirSyncActive();
  const checkRedirectToEventsAir = useCheckRedirectToEventsAir();
  const bookedTicketsForEventsAir = useBookedTicketsForEventsAir();
  const ifTrackThisPage = !routeMatchExcludeList?.some((routeMatch) => Object.keys(routeMatch).some((locationKey) => new RegExp(routeMatch[locationKey]).test(location[locationKey])));
  const onEnter = ifTrackThisPage
    ? [...onEnterProps, ...onEnterTracking]
    : onEnterProps;
  const onLeave = ifTrackThisPage
    ? [...onLeaveProps, ...onLeaveTracking]
    : onLeaveProps;
  const windowRelativeLocation = `${window.location.pathname}${window.location.search}${window.location.hash}`;

  useEffect(() => {
    // adding page className to vmRevolutionRoot when enter to new page and removing it when change page
    const root = document.getElementById('vmRevolutionRoot');
    root.classList.add(className);
    return () => root.classList.remove(className);
  }, []);

  useEffect(() => {
    if (!fakeRender) {
      dispatch(changeCurrentPageInfo(title, pageName));
    }
  }, [title]);

  useEffect(() => {
    if (!pageId || !isReady) return () => null;
    const triggerEvent = (vmEventList) => {
      const dataBank = {
        vmFunctions,
        props: { ...props, apiData: { ...apiDataGlobal, ...apiDataPage } }
      };
      dispatchVmEventList(vmEventList, dataBank);
    };

    triggerEvent(onEnter);
    return () => {
      triggerEvent(onLeave);
    };
  }, [
    pageId,
    isReady,
    pageUrlParamId,
    onEnterProps,
    onEnterTracking,
    onLeaveProps,
    onLeaveTracking,
    apiDataGlobal,
    apiDataPage
  ]);

  if (restProps?.forceData && apiDataPage) {
    // Force data - comes from preview pages ( Test-flight )
    // Avoid using it in our layoutSettings.
    if (apiDataPage[restProps.forceData.dataMask]) {
      apiDataPage[restProps.forceData.dataMask].data = restProps.forceData.data;
    }
  }

  if (!navigator.onLine && !skipOfflineCheck) {
    // if we're offline
    console.log('apiDataPage', apiDataPage);

    // Here i will test, if i should forward the user to the offline page.
    if (apiDataPage) {
      const apiEntries = Object.keys(apiDataPage);
      if (apiEntries.length > 0) {
        // loop to make sure they are not all errors.
        let countFetchErrors = 0;
        apiEntries.forEach((entry) => {
          if (
            apiDataPage[entry].isLoading === false
            && apiDataPage[entry].isError === true
          ) {
            countFetchErrors += 1;
          }
        });
        console.log('countFetchErrors', countFetchErrors, apiEntries.length);
        // if the ammount of api errors=== the amount of api calls on the page
        // then show offline.
        if (countFetchErrors === apiEntries.length) {
          // return "YOU ARE OFFLINE"
          return <OfflinePageRenderer {...props} />;
        }
      }
    }
  }

  // TODO: Page Error handling
  if (!isReady) return <Loading />;

  if (
    window.location.pathname !== '/auth/login'
    && window.location.pathname !== '/logout'
  ) {
    storageAndCookie.set('previousLocation', windowRelativeLocation);
  }

  if (isHoldingPageActive && checkRedirectToHolding(windowRelativeLocation)) {
    return <RedirectToHolding previousLocation={windowRelativeLocation} />;
  }
  if (isEventsAirSyncActive) {
    if (bookedTicketsForEventsAir.isLoading) return <Loading />;
    if ('externalAuthorizationCode' in urlSearchParams) storageAndCookie.set('externalAuthorizationCode', urlSearchParams.externalAuthorizationCode, 1, true);
    if (checkRedirectToEventsAir(windowRelativeLocation)) return <RedirectToEventsAirAuth />;
    if (bookedTicketsForEventsAir.error) return <RedirectToNoAccess />;
  }

  // If this page is only for logged in users, render modal
  if (isProtectedUrl(window.location.pathname)) {
    return (
      <Suspense fallback={<Loading />}>
        <ComponentsArrRenderer
          {...restProps}
          components={loggedInOnlySettings?.components}
          parent={['PAGE']}
        />
      </Suspense>
    );
  }

  // If this page is only for members, render modal
  if (
    membershipOnlySettings?.isActive
    && isMemberOnlyAccessUrl
    && !isUserAMember
  ) {
    return (
      <Suspense fallback={<Loading />}>
        <ComponentsArrRenderer
          {...restProps}
          components={membershipOnlySettings?.components}
          parent={['PAGE']}
        />
      </Suspense>
    );
  }

  if (isUserLoggedIn()) {
  // Deal with user-profile-data:
    if (isUserProfileDataActive) {
      const { apiStatus, isCompleted } = userProfileData;
      const { urls, components: updComponents } = userProfileDataConfig;
      // TODO: Handle api status error
      if (apiStatus !== 'ready') return <Loading />;
      if (!isCompleted && checkReservedUrl(window.location.pathname, urls)) {
        return (
          <Suspense fallback={<Loading />}>
            <ComponentsArrRenderer
              {...restProps}
              components={updComponents}
              parent={['PAGE']}
            />
          </Suspense>
        );
      }
    }
  }

  // If this page is only for communicable, render modal
  if (
    isMyNetworkingActive
    && isStatusInitReady()
    && communicableOnlySettings?.isActive
    && isCommunicableOnlyAccessUrl
    && !isCommunicable()
  ) {
    return (
      <Suspense fallback={<Loading />}>
        <ComponentsArrRenderer
          {...restProps}
          components={communicableOnlySettings?.components}
          parent={['PAGE']}
        />
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<Loading />}>
      <ComponentsArrRenderer
        {...restProps}
        handlerList={handlerList}
        apiData={{ ...apiDataGlobal, ...apiDataPage }}
        parent={['PAGE']}
      />
    </Suspense>
  );
};

PageRenderer.propTypes = {
  title: PropTypes.string,
  pageName: PropTypes.string.isRequired,
  components: componentsPropType.isRequired,
  apiDataInfo: apiDataInfoPropType,
  // TODO: Add this to global prop types
  urlParams: PropTypes.shape({
    id: PropTypes.string
  }),
  urlSearchParams: PropTypes.shape({}),
  handlerList: PropTypes.shape({}),
  onEnter: PropTypes.array,
  onLeave: PropTypes.array,
  id: PropTypes.string,
  apiData: apiDataPropType,
  skipOfflineCheck: PropTypes.bool,
  fakeRender: PropTypes.bool,
  className: PropTypes.string
};

PageRenderer.defaultProps = {
  onEnter: [],
  onLeave: []
};

export default PageRenderer;
